import './profile.scss';
import { bindable, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { CustomerService } from 'services/customer-service';
import { ImageService } from 'services/image-service';
import { apiEndpoint } from 'environment';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ToastService } from 'services/toast-service';
import { FullnameValueConverter } from 'resources/value-converters/fullname-formatter';
import { DateFormatterValueConverter } from 'resources/value-converters/date-formatter';
import { PhoneFormatValueConverter } from 'resources/value-converters/phone-formatter';
import { CapitalizeTextValueConverter } from 'resources/value-converters/capitalize-text';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class Profile {
    constructor(
        private router: Router,
        private sessionService: SessionService,
        private customerService: CustomerService,
        private imageService: ImageService,
        private eventAggregator: EventAggregator,
        private toastService: ToastService,
        private fullnameValueConverter: FullnameValueConverter,
        private dateFormatterValueConverter: DateFormatterValueConverter,
        private phoneFormatValueConverter: PhoneFormatValueConverter,
        private capitalizeTextValueConverter: CapitalizeTextValueConverter,
        private websiteService: WebsiteService) { }

    @bindable phoneInput;
    @bindable loadingPhoneInput;
    environment = apiEndpoint();
    showMessage;
    idText = 'Please select document';
    idState;
    idUploading = false;
    addressText = 'Please select document';
    addressState;
    addressUploading = false;
    selfieText = 'Please select document';
    selfieState;
    selfieUploading = false;
    pageLoading;
    saving = false;
    editingName = false;
    editingLastName = false;
    isVerified = false;
    statusFieldPhone = false;
    statusFieldName = false;
    statusFieldEmail = false;
    statusFieldAddress = false;
    showPhoneTooltip = false;
    showNameTooltip = false;
    showEmailTooltip = false;
    showAddressTooltip = false;
    width: number;
    pages;
    customerPortalRoute;

    user;
    userVeriffData;

    phoneSubscriber;
    userSubscriber;
    dialogModule;
    avatarDialogModule;
    bannerSubscriber;
    emailSent;
    sizeSubscriber;

    async activate() {
        this.user = await this.sessionService.refreshProfile();
        if (!this.user) {
            this.router.navigateToRoute('home');
            return;
        }
        this.userVeriffData = await this.customerService.getVeriffUserData(this.user.id);
    }

    async attached() {
        this.pageLoading = true;
        this.user = this.user ?? await this.sessionService.refreshProfile();
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        if (this.user) {
            [this.user.emailInReview, this.user.idVerificationInReview, this.user.addressVerificationInReview, this.user.selfieVerificationInReview, this.pages] = await Promise.all([
                this.sessionService.getEmailInReview(),
                this.sessionService.getIdVerificationInReview(this.user.id),
                this.sessionService.getAddressVerificationInReview(this.user.id),
                this.sessionService.getSelfieVerificationInReview(this.user.id),
                this.websiteService.getPagesByWebsiteShortcode()
            ]);
            if (!this.user.idVerified && this.user.idVerificationInReview) {
                this.idText = 'Review in progress';
                this.idState = 'warning';
            }
            if (!this.user.addressVerified && this.user.addressVerificationInReview) {
                this.addressText = 'Review in progress';
                this.addressState = 'warning';
            }
            if (!this.user.selfieVerified && this.user.selfieVerificationInReview) {
                this.selfieText = 'Review in progress';
                this.selfieState = 'warning';
            }
            this.handleEventSubscriptions();
        }

        this.customerPortalRoute = this.pages.find(x => x.name === 'Customer Portal')?.routeName ?? 'customer-portal';

        const tooltipEl = document.querySelectorAll('.mdc-tooltip');
        tooltipEl.forEach(element => {
            element?.querySelector('.mdc-tooltip__surface.mdc-tooltip__surface-animation');
            element.setAttribute('style', 'left: -82px;');
        });

        this.pageLoading = false;
        this.eventAggregator.publish('avatar-dialog-open', { avatarDialogOpen: false });
    }

    detached() {
        this.phoneSubscriber?.dispose();
        this.userSubscriber?.dispose();
        this.bannerSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.phoneSubscriber = this.eventAggregator.subscribe('phone-updated', payload => {
            if (payload.successful) {
                this.user.phoneNumberConfirmed = true;
            }
        });

        this.userSubscriber = this.eventAggregator.subscribe('user-updated', async (payload) => {
            this.user = payload.user;
            if (this.user) {
                [this.user.emailInReview, this.user.phoneNumberInReview, this.user.idVerificationInReview, this.user.addressVerificationInReview, this.user.selfieVerificationInReview, this.userVeriffData] = await Promise.all([
                    this.sessionService.getEmailInReview(),
                    document.getElementById('code') ? true : false,
                    this.sessionService.getIdVerificationInReview(this.user.id),
                    this.sessionService.getAddressVerificationInReview(this.user.id),
                    this.sessionService.getSelfieVerificationInReview(this.user.id),
                    this.customerService.getVeriffUserData(this.user.id)
                ]);
            }
        });

        this.bannerSubscriber = this.eventAggregator.subscribe('banner-updated', payload => {
            if (!payload.optedIn) return;
            this.user.optedInForEmails = true;
        });

        this.sizeSubscriber = this.eventAggregator.subscribe('size-changed', async payload => {
            this.width = payload.width;
        });
    }

    checkUserBillingAddress() {
        return this.user?.address && this.user?.city && this.user?.state && this.user?.country;
    }

    getUserBillingAddress() {
        return `${this.user?.address}, ${this.user?.city}, ${this.user?.state}, ${this.user?.country.toUpperCase()}`;
    }

    checkUserVerification() {
        if (this.user.phoneNumberConfirmed || this.user.emailConfirmed) {
            this.isVerified = true;
            const updateButtons = document.getElementsByClassName('update-btn');
            for (const updateButton of Array.from(updateButtons)) {
                updateButton.classList.add('disabled-btn');
            }
            return true;
        }
    }

    async redirectToVerification(field) {
        if (field) {
            await this.router.navigate(`/${this.customerPortalRoute}/verification`);
            const option = 'verification';
            this.eventAggregator.publish('route-updated', { option: option });
        }
    }

    loadTooltips(id, status) {
        let verified = '';
        let pending = '';
        let content = '';
        let firstName = '';
        let birthDay = '';
        switch (id) {
            case 'name':
                firstName = this.fullnameValueConverter.toView(this.userVeriffData ? this.userVeriffData?.firstName : this.user?.firstName, this.userVeriffData ? this.userVeriffData?.lastName : this.user?.lastName);
                birthDay = this.userVeriffData ? this.dateFormatterValueConverter.toView(this.userVeriffData?.dateOfBirth, 'format', 'DD/MM/yyyy', true) : '-';
                verified = `Your verified name is ${ firstName } (DOB: ${ birthDay }), to update please contact support@divicasales.com`;
                pending = 'Your ID is currently pending. To verify please proceed with the verification process in the verification tab.';
                content = status === 'verified' ? verified : pending;
                break;
            case 'phone':
                verified = `Your phone number is ${ this.phoneFormatValueConverter.toView(this.user?.phoneNumber, this.user?.phoneCountryCode) }, to update please contact support@divicasales.com`;
                pending = 'Your phone number is currently pending. To verify please proceed with the verification process in the verification tab.';
                content = status === 'verified' ? verified : pending;
                break;
            case 'email':
                verified = `Your verified email is ${ this.user?.email }, to update please contact support@divicasales.com`;
                pending = 'Your email address is currently pending. To verify please proceed with the verification process in the verification tab.';
                content = status === 'verified' ? verified : pending;
                break;
            case 'address':
                verified = `Your verified ${ this.userVeriffData?.addresses?.length > 0 && this.checkUserBillingAddress() ? `billing addresses are "${ this.userVeriffData?.addresses[0]?.fullAddress }" & ` : this.userVeriffData?.addresses?.length > 0 && !this.checkUserBillingAddress() ? `billing address is "${ this.userVeriffData?.addresses[0]?.fullAddress }"` : 'billing address is'} ${ this.checkUserBillingAddress() ? `"${ this.getUserBillingAddress() }"` : '' }, to update it please contact support@divicasales.com`;
                pending = 'Your billing address is currently pending. To verify please upload a bill, statement or invoice to confirm in the verification tab.';
                content = status === 'verified' ? verified : pending;
                break;
        }
        return content;
    }

    mouseEventField(field, event) {
        switch (field) {
            case 'name':
                this.statusFieldName = event.type === 'mouseenter';
                break;
            case 'phone':
                this.statusFieldPhone = event.type === 'mouseenter';
                break;
            case 'email':
                this.statusFieldEmail = event.type === 'mouseenter';
                break;
            case 'address':
                this.statusFieldAddress = event.type === 'mouseenter';
                break;
            default:
                break;
        }
    }

    loadingPhoneInputChanged() {
        if (!this.loadingPhoneInput) {
            this.pageLoading = false;
        }
    }

    async updateInformation() {
        try {
            this.saving = true;
            if (this.phoneInput) {
                this.user.phoneCountryCode = this.phoneInput.getSelectedCountryData().dialCode;
                this.user.phoneNumber = this.phoneInput.getNumber().slice(1 + this.user.phoneCountryCode.length);
            }
            if (this.user.optedInForEmails) {
                this.user.closedSubscriptionBanner = true;
            }
            const response = await this.customerService.updateInformation(this.user);
            if (response) {
                [response.idVerificationInReview, response.addressVerificationInReview, response.selfieVerificationInReview] = await Promise.all([
                    this.sessionService.getIdVerificationInReview(this.user.id),
                    this.sessionService.getAddressVerificationInReview(this.user.id),
                    this.sessionService.getSelfieVerificationInReview(this.user.id),
                ]);
                this.user = response;
                this.eventAggregator.publish('user-updated', { user: this.user });
                this.saving = false;
                this.editingLastName = false;
                this.editingName = false;
                await this.toastService.showToast('Updated profile', 'Your profile has been updated successfully.', 'success');
            } else {
                this.saving = false;
                await this.toastService.showToast('Failed to update profile', 'Please check that all field are valid.', 'error');
            }
        } catch (e) {
            console.log(e);
            this.saving = false;
            await this.toastService.showToast('Failed to update profile', 'Please check that all field are valid.', 'error');
        }
    }

    handleDialogOpen() {
        this.dialogModule.dialog.open();
    }

    handleAvatarDialogOpen() {
        this.eventAggregator.publish('avatar-dialog-open', { avatarDialogOpen: true });
        this.avatarDialogModule.dialog.open();
    }

    async deleteProfile(password) {
        try {
            const response = await this.customerService.deleteProfile(password);
            if (response) {
                await this.toastService.showToast('Account deleted', 'As a result you have been logged out, please Sign up again to access your account or contact Support.', 'info');
                await this.sessionService.logout();
                return this.router.navigateToRoute('home');
            } else {
                await this.toastService.showToast('Failed to delete account', 'Please contact Support.', 'error');
            }
        } catch (e) {
            console.log(e);
            await this.toastService.showToast('Failed to delete account', 'Please contact Support.', 'error');
        }
    }

    async handleAvatarUpdate(e) {
        this.saving = true;
        let file;
        if (e?.target?.files) {
            file = e.target.files;
        }
        if (file) {
            const result = this.imageService.buildFormData(file);
            if (result) {
                this.user.avatarImagePath = await this.imageService.postAvatar(result);
                this.eventAggregator.publish('user-updated', { user: this.user });
                this.saving = false;
                await this.toastService.showToast('Updated profile', 'Your avatar has been updated successfully.', 'success');

            }
        } else {
            this.saving = false;
            await this.toastService.showToast('No file selected', 'Please select a file before trying to submit.', 'info');
        }
    }

    async handleIdUpload(data) {
        try {
            this.idState = null;
            this.idUploading = true;
            const response = await this.imageService.postClientDocument(data.formData, data.verificationCategoryId, data.documentTypeId);
            if (response?.id) {
                this.idUploading = false;
                this.idState = 'success';
                this.idText = 'Uploaded successfully!';
                setTimeout(async () => {
                    this.idText = 'Review in progress';
                    this.idState = 'warning';
                    this.user.idVerificationInReview = true;
                    this.eventAggregator.publish('user-updated', { user: this.user });
                }, 1000);
            } else {
                this.idUploading = false;
                this.idState = 'error';
                this.idText = 'Document failed to upload';
            }
        } catch (e) {
            this.idUploading = false;
            this.idState = 'error';
            this.idText = 'Document failed to upload';
        }
    }

    async handleAddressUpload(data) {
        try {
            this.addressState = null;
            this.addressUploading = true;
            const response = await this.imageService.postClientDocument(data.formData, data.verificationCategoryId, data.documentTypeId);
            if (response?.id) {
                this.addressUploading = false;
                this.addressState = 'success';
                this.addressText = 'Uploaded successfully!';
                setTimeout(async () => {
                    this.addressText = 'Review in progress';
                    this.addressState = 'warning';
                    this.user.addressVerificationInReview = true;
                    this.eventAggregator.publish('user-updated', { user: this.user });
                }, 1000);
            } else {
                this.addressUploading = false;
                this.addressState = 'error';
                this.addressText = 'Document failed to upload';
            }
        } catch (e) {
            this.addressUploading = false;
            this.addressState = 'error';
            this.addressText = 'Document failed to upload';
        }
    }

    async handleSelfieUpload(data) {
        try {
            this.selfieState = null;
            this.selfieUploading = true;
            const response = await this.imageService.postClientDocument(data.formData, data.verificationCategoryId, data.documentTypeId);
            if (response?.id) {
                this.selfieUploading = false;
                this.selfieState = 'success';
                this.selfieText = 'Uploaded successfully!';
                setTimeout(async () => {
                    this.selfieText = 'Review in progress';
                    this.selfieState = 'warning';
                    this.user.selfieVerificationInReview = true;
                    this.eventAggregator.publish('user-updated', { user: this.user });
                }, 1000);
            } else {
                this.selfieUploading = false;
                this.selfieState = 'error';
                this.selfieText = 'Document failed to upload';
            }
        } catch (e) {
            this.selfieUploading = false;
            this.selfieState = 'error';
            this.selfieText = 'Document failed to upload';
        }
    }

    async sendConfirmationEmail() {
        try {
            const sent = await this.customerService.requestEmailConfirmation();
            if (sent) {
                this.user.emailInReview = true;
                await this.sessionService.saveEmailInReview(this.user.emailInReview);
                this.emailSent = true;
                this.showMessage = true;
                this.eventAggregator.publish('user-updated', { user: this.user });
            }
        } catch (e) {
            console.log(e);
        }
    }

    async emailUpdatedOnKeyPress() {
        const el = document.getElementById('resend-verification');
        el.innerHTML = 'Send verification code';
        await this.updateInformation();
    }

    async setEmailNotInReview() {
        this.user.emailInReview = false;
        this.showMessage = false;
        await this.sessionService.saveEmailInReview(this.user.emailInReview);
        this.eventAggregator.publish('user-updated', { user: this.user });
    }

    displayMessage() {
        this.showMessage = true;
    }

    mouseEventsTooltips(ev, id) {
        const isMouseEnter = ev.type === 'mouseenter';
        switch (id) {
            case 'name':
                this.showNameTooltip = isMouseEnter;
                break;
            case 'phone':
                this.showPhoneTooltip = isMouseEnter;
                break;
            case 'email':
                this.showEmailTooltip = isMouseEnter;
                break;
            case 'address':
                this.showAddressTooltip = isMouseEnter;
                break;
            default:
                break;
        }
        if (isMouseEnter) {
            setTimeout(() => {
                const tooltipEl = document.querySelector('.mdc-tooltip.mdc-tooltip--shown');
                const tooltipContent = tooltipEl?.querySelectorAll('.mdc-tooltip__surface.mdc-tooltip__surface-animation');
                const style = tooltipEl?.getAttribute('style');
                if (tooltipEl?.clientHeight > 80 && this.width > 576) {
                    let left = parseFloat(style?.substring(style.indexOf('left:') + 6, style.indexOf('px;', style.indexOf('left:'))));
                    const maxWidth = 270;
                    left = left - ((maxWidth - tooltipEl.clientWidth) / 2);
                    tooltipEl.setAttribute('style', `${ style.substring(0, style.indexOf('left:')) }left: ${ left }px; min-width: ${ maxWidth }px;`);
                    tooltipContent.forEach(element => element.setAttribute('style', `${ style.substring(0, style.indexOf('left:')) }left: ${ left }px; min-width: ${ maxWidth }px;`));
                }
            }, 10);
        }
    }
}
