import './app.scss';
import { computedFrom } from 'aurelia-framework';
import 'simplebar/dist/simplebar.css';
import { PLATFORM } from 'aurelia-pal';
import { Router, activationStrategy } from 'aurelia-router';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { AuthorizeStep } from 'resources/pipelines/authorize-step';
import { PreActivateStep } from 'resources/pipelines/preactivate-step';
import { MetadataStep } from 'resources/pipelines/metadata-step';
import { SeoStep } from 'resources/pipelines/seo-step';
import { IntercomStep } from 'resources/pipelines/intercom-step';
import { ScrollPageStep } from 'resources/pipelines/scroll-page-step';
import { autoinject } from 'aurelia-dependency-injection';
import { SignalrService } from 'services/signalr-service';
import { HubConnection } from '@microsoft/signalr';
import { SessionService } from 'services/session-service';
import { PageContentAreaService } from 'services/page-content-area-service';
import { InterComStylingService } from 'services/intercom-styling-service';
import { CurrencyService } from 'services/currency-service';
import { HealthService } from 'services/health-service';
import { WebsiteService } from 'services/website-service';
import { IpAddressService } from 'services/ip-address-service';
import { BlacklistService } from 'services/blacklist-service';
import { baseUrl } from 'environment';
import { CapitalizeTextValueConverter } from 'resources/value-converters/capitalize-text';
import { ProductCategoryService } from 'services/product-category-service';
import { BlogService } from './services/blog-service';
import { ProductService } from 'services/product-service';
import { ToastService } from 'services/toast-service';
import { CustomerService } from 'services/customer-service';
import isOnline from 'is-online';
import SimplebarOverride from 'resources/simplebar_override';
import { Helper } from 'resources/extensions/helper';
import { TypeExtensions } from 'resources/extensions/type_extensions';
import { SardineAi } from 'resources/helpers/sardine-ai';
import { AuthenticationExtension } from 'resources/extensions/sso_extension';
import { DesktopBreakpoint } from 'resources/constants';
import ToastManager, { ToastPosition, NotificationType } from '@chicksgroup/notifications';
import { Toast } from 'services/models/toast';
import '@stripe/stripe-js';

const Notifications = new ToastManager({
    toast: {
        preventDuplicates: true,
        maxAmount: 3,
        timeout: 3000,
        position: ToastPosition.TopRight,
        xMargin: 20,
        yMargin: 40,
        separation: 30
    },
    transformToasts: true,
    snackbar: {
        timeout: 3000
    }
});

@autoinject()
export class App extends AuthenticationExtension {
    private user;
    private currencyOptions = [];
    private router: Router;
    private healthTimeout: NodeJS.Timer;
    private triggeredConnection: boolean;
    private connection: HubConnection;
    private userSubscriber;
    private veriffSubscriber;
    private routerSubscriber;
    hideIntercomIcon: boolean;
    width: number;
    height: number;
    triggeredMonitoring: boolean;
    triggeredBanner: boolean;
    urlParams;
    userBlacklist;
    pages;
    firstTimeBreadcrumbCall;
    untrackUserConnectionSubscriber: Subscription;
    blogRoute;
    property;
    private scrollbar: SimplebarOverride;
    observeSubscriber: Subscription;
    observing = ['#main-page-host'];
    resizeObserver: ResizeObserver;

    constructor(
        private eventAggregator: EventAggregator,
        private signalRService: SignalrService,
        sessionService: SessionService,
        private pageContentAreaService: PageContentAreaService,
        private intercomStylingService: InterComStylingService,
        private currencyService: CurrencyService,
        private healthService: HealthService,
        private websiteService: WebsiteService,
        private ipAddressService: IpAddressService,
        private blacklistService: BlacklistService,
        private capitalizeTextValueConverter: CapitalizeTextValueConverter,
        private productCategoryService: ProductCategoryService,
        private blogService: BlogService,
        private productService: ProductService,
        private customerService: CustomerService,
        toastService: ToastService,
        private helper: Helper,
        private typeExtensions: TypeExtensions,
        private sardineAi: SardineAi
    ) {
        super(toastService, sessionService);

        eventAggregator.subscribe('toast', (toast: Toast) => {
            this.onToastReceived(toast);
        });

        this.typeExtensions.implement(helper);
    }

    async activate() {
        await this.handleSSO();
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        [this.user, this.pages, this.currencyOptions] = await Promise.all([
            this.sessionService.getProfile(),
            this.websiteService.getPagesByWebsiteShortcode(),
            this.sessionService.getCurrencyOptions(),
            this.currencyService.getAllCurrencyRates('DS')
        ]);
        this.blogRoute = this.getRouteNameForPage(this.pages, 'Blog') ?? 'blog';
        if (!this.user) {
            await this.ipAddressService.getIpData();
        }
        await this.pageContentAreaService.getByPageId(this.pages.find(x => x.name === 'Footer')?.id);
    }

    async attached() {
        this.handleEventSubscriptions();

        this.scrollbar = new SimplebarOverride(document.getElementById('main-page-host'));
        this.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        this.handleWindowResizement();
        this.urlParams = new URLSearchParams(window.location.search);

        if (this.urlParams.get('openLivechat') && window.Intercom) {
            window.Intercom('show');
        }

        if (this.user) {
            await this.ipAddressService.postIp();
            this.userBlacklist = await this.blacklistService.getBlacklistByUserEmail(this.user.email);
            await this.sardineAi.handleSardineFlow(this.user.id, this.user.sardineSessionKey, 'onboarding');
        }

        this.handleReferralAndReferrerLinks();
        this.handleSignalRConnection();

        //Styling & Google Snippet calls
        this.firstTimeBreadcrumbCall = true;
        this.handleBreadcrumbList();
        this.handleReviewSchema();
        this.interComDeviceStyling();

        //Event & Script calls
        this.handleMonitoringWebsite();
        this.handleGoogleTagManagerUserId(this.user);
        this.helper.handlePrerender404(this.router);
        this.handleLinksWithinContent();

        if (this.user) {
            if (this.userBlacklist?.some(x => x.level === 3 || x.level === 4)) {
                if (window.Intercom) {
                    window.Intercom('shutdown');
                }
                return;
            }
        }
        this.sessionService.initializeLiveChat();
        setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).prerenderReady = true;
        }, 10000);

        //Favicon on load
        this.setFavicon();

        //Change favicon when user changes color mode
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => this.setFavicon());
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
    }

    setFavicon(): void {
        if (baseUrl().includes('localhost')) return;
        const link = document.querySelector('link[rel="icon"]') || document.createElement('link');
        (link as HTMLLinkElement).rel = 'icon';
        (link as HTMLLinkElement).type = 'image/x-icon';
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            (link as HTMLLinkElement).href = '/favicons/favicon-dark.ico';
        } else {
            (link as HTMLLinkElement).href = '/favicons/favicon.ico';
        }
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    handleLinksWithinContent() {
        const links = Array.from(document.getElementsByTagName('a'));
        links.forEach(link => {
            if (link.href.includes(baseUrl())) {
                link.removeAttribute('rel');
            }
        });
    }

    handleEventSubscriptions() {
        this.routerSubscriber = this.eventAggregator.subscribe('router:navigation:success', async () => {
            this.handleLinksWithinContent();
            if (!this.firstTimeBreadcrumbCall) {
                this.handleBreadcrumbList();
            }
            if (this.user) {
                this.property = await this.customerService.checkIfUserDeleted();
                if (this.property?.isDeleted) {
                    this.toastService.showToast('Info', 'Account deleted. As a result, you have been logged out. Sign-up again to log back in, or contact support.', 'info');
                    await this.sessionService.logout();
                }
                if (await this.sessionService.verifyIsUserInBlackList(this.user.email, null)) {
                    await this.sessionService.logout();
                }
            }
        });

        this.observeSubscriber = this.eventAggregator.subscribe('observe-element', async payload => {
            this.observing ??= ['#main-page-host'];
            const element = this.observing?.findIndex(x => x === payload.selector);
            if (element === -1) this.observing?.push(payload.selector);
            this.handleWindowResizement();
        });

        this.userSubscriber = this.eventAggregator.subscribe('user-updated', async (payload) => {
            this.handleGoogleTagManagerUserId(payload.user);
            if (payload) {
                if (payload.forDelete) {
                    this.user = undefined;
                    if (payload.redirect) this.router.navigateToRoute('home');
                }
                else {
                    const profile = await this.sessionService.getProfile();
                    if (payload.user?.id === profile?.id) {
                        this.user = payload.user;
                        await this.sardineAi.handleSardineFlow(this.user.id, this.user.sardineSessionKey, 'login');
                        this.ipAddressService.postIp();
                        this.handleSignalRConnection();
                        this.sessionService.initializeLiveChat();
                        await this.signalRService.trackUserConnection();
                    }
                }
            }
        });

        this.untrackUserConnectionSubscriber = this.eventAggregator.subscribe('untrack-user', async() => {
            await this.signalRService.untrackUserConnection();
        });
    }

    async handleReferralAndReferrerLinks() {

        const referralLink = this.urlParams.get('ref');
        const platformLink = this.urlParams.get('gclid') ?? this.urlParams.get('msclkid');

        if (referralLink) {
            this.sessionService.saveReferralLink(referralLink);
            this.urlParams.delete('ref');
        }

        const referrerLink = document.referrer;
        if (referrerLink && referrerLink !== '' && !referralLink && !referrerLink.includes('divicasales') && !referrerLink.includes('localhost') && !referrerLink.includes('user.chicksgroup')) {
            this.sessionService.saveReferrerLink(referrerLink);
        }

        if (platformLink) {
            let platform;
            this.urlParams.has('gclid') ? platform = 'gclid' : platform = 'msclkid';
            this.sessionService.setPlatformLinkCookie(platform, platformLink, 3);
        }
    }

    handleWindowResizement() {
        if (!window.ResizeObserver) return;
        this.observing ??= ['#main-page-host'];
        this.observing = this.observing.filter(x => document.querySelector(x));
        this.resizeObserver?.disconnect();
        this.resizeObserver = new ResizeObserver(async entries => {
            for (const entry of entries) {
                const width = entry?.contentRect.width;
                const height = entry?.contentRect.height;
                const isHost = entry.target.id === 'main-page-host';
                if (isHost) {
                    this.width = width;
                    this.height = height;
                    this.interComDeviceStyling();
                }
                const payload = {
                    width,
                    height,
                    target: entry.target
                };
                let event = 'size-changed';
                if (!isHost) event += `-${entry.target.id}`;
                this.eventAggregator.publish(event, payload);
            }
        });
        this.observing.forEach(x => {
            if (x) this.resizeObserver.observe(document.querySelector(x));
        });
    }

    interComDeviceStyling() {
        let intercom = document.getElementById('intercom-style');

        this.intercomStylingService.handleStyles(this.width);

        this.veriffSubscriber = this.eventAggregator.subscribe('opened-veriff-dialog', payload => {
            this.hideIntercomIcon = payload;
            if (this.hideIntercomIcon) {
                if (!intercom) {
                    intercom = document.createElement('style');
                    intercom.setAttribute('id', 'intercom-style');
                }
                intercom.innerHTML = '.intercom-launcher-frame, .intercom-app > div:nth-child(2) { display: none !important; } .intercom-lightweight-app-launcher { display: none !important; } .intercom-messenger-frame { display: none !important; } #intercom-facade-btn { display: none !important; }';
            }
        });
    }

    async handleSignalRConnection() {
        if (!this.triggeredConnection) {
            this.triggeredConnection = true;
            this.connection = await this.signalRService.setupSignalRConnection();
            await this.signalRService.start(this.connection);

            this.connection.on('ApiRedeployment', async(response) => {
                if (response) {
                    this.eventAggregator.publish('banner-updated', { successful: 'warning', text: 'Divica Sales was updated. To refresh, please', clickFunctionName: 'refresh' });
                }
            });

            this.connection.on('Logout', this.sessionService.logout);

            if (this.user) {
                await this.signalRService.trackUserConnection();
            }
        }
    }

    onToastReceived(toast: Toast) {
        if (this.width <= DesktopBreakpoint) {
            this.eventAggregator.publish('opened-veriff-dialog', { openedVeriffDialog: true, triggerDeviceStyling: true });
            Notifications.add(
                { type: NotificationType.Snackbar,
                    message: toast.message,
                    severity: this.getToastType(toast.toastType),
                    onDetached: () => this.openVeriff(),
                });
            return;
        }
        Notifications.add({ type: NotificationType.Toast, title: toast.title, message: toast.message, severity: this.getToastType(toast.toastType) });
    }

    getToastType(toast: string) {
        return toast === 'error' ? 'error' : toast === 'warning' ? 'warning' : toast === 'success' ? 'success' : 'info';
    }

    openVeriff = () => {
        this.eventAggregator.publish('opened-veriff-dialog', { openedVeriffDialog: false });
    };

    handleGoogleTagManagerUserId(user) {
        const scriptElement = document.getElementById('google-tag-manager');
        if (!scriptElement) {
            const s = document.createElement('script');
            s.setAttribute('id', 'google-tag-manager');
            s.innerHTML = this.getGoogleTagManagerUserIdScript(user);
            document.head.appendChild(s);
        } else {
            scriptElement.innerHTML = this.getGoogleTagManagerUserIdScript(user);
        }
    }

    isRouteAnyPage = (pages) => pages.some(page => this.router?.currentInstruction.config.name.includes(page));

    async handleBreadcrumbList() {
        const paramNames = Object.keys(this.router?.currentInstruction?.params);
        const configName = this.router?.currentInstruction?.config?.name;
        let configRoute = '';
        const dynamicPagesForNav = await this.productCategoryService.getAllForNav('DS');
        let foundChildRoute;
        if (configName === 'sell') {
            const sellCategory = this.router.currentInstruction.fragment.split('/');
            foundChildRoute = dynamicPagesForNav.find(x => x.name.toLowerCase() === `${sellCategory[1]} ${sellCategory[2]}`.toLowerCase());
        } else {
            foundChildRoute = dynamicPagesForNav.find(x => x.name.toLowerCase() === this.router?.currentInstruction.config.name.replace('-', ' ').toLowerCase());
        }
        if (foundChildRoute?.gameForNav.length) {
            foundChildRoute = foundChildRoute.gameForNav.find(x => x.slug.find(y => y === window.location.pathname.split('/').pop()));
        }

        if (this.router?.currentInstruction.config.navModel.config.route.includes('/')) {
            configRoute = (this.router?.currentInstruction.config.navModel.config.route as string).split('/')[0];
        }

        let breadCrumbSchema = `{
                            "@type": "BreadcrumbList",
                            "@id": "${baseUrl()}#/schema/BreadcrumbList/1",
                            "itemListElement": [{ 
                                "@type": "ListItem",
                                "position": 1,
                                "name": "${this.capitalizeTextValueConverter.toView(configName === 'item' || configName === 'account'
        ? `${configName?.replaceAll('-', ' ')}s`
        : configName === 'blog-post'
            ? 'Blogs'
            : configName?.replaceAll('-', ' '), 'sentence')}",
                                "item": "${`${baseUrl()}${this.router?.currentInstruction.config.navModel.config.route}`}"`;

        if (!['sell', 'blog-post'].some(x => x.includes(configName)) && (paramNames.length === 1 && paramNames[0] !== 'page') || (this.isRouteAnyPage(['items', 'accounts', 'skins']) && configName?.includes('game'))) {
            breadCrumbSchema += `}, {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "${foundChildRoute?.name ?? this.capitalizeTextValueConverter.toView((Object.values(this.router?.currentInstruction.params)[0] as string).replace(/\d+/g, '').replaceAll('-', ' ').replaceAll('/', ' ').trim(), 'sentence')}",
                                "item": "${`${baseUrl()}${configRoute}`}"
                            }]
                        }`;
        } else if (configName === 'blog-post' || (paramNames.length >= 2 && ['item', 'skin', 'account'].some(x => x.includes(configName)) && this.router.currentInstruction.params.id)) {
            let itemObject;
            if (configName === 'blog-post') {
                itemObject = await this.blogService.getBlogBySlug(this.router.currentInstruction.params.blog);
            } else {
                itemObject = await this.productService.getProductById(this.router.currentInstruction.params.id);
            }
            breadCrumbSchema += `}, {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "${configName === 'blog-post' ? itemObject.blogTag?.name : itemObject.game.name}",
                                "item": "${configName === 'blog-post'
        ? `${baseUrl().slice(0, -1)}/${this.router?.currentInstruction.config.route}/category/${itemObject.blogTag?.name?.toLowerCase()}`
        : `${baseUrl().slice(0, -1)}/${this.router?.currentInstruction.config.route}/${this.router?.currentInstruction.params.game}`}"
                            }, {
                                "@type": "ListItem",
                                "position": 3,
                                "name": "${configName === 'blog-post' ? this.router.currentInstruction.config.navModel.title : itemObject.name}",
                                "item": "${`${baseUrl().slice(0, -1)}${this.router?.currentInstruction.fragment}`}"
                            }]
                        }`;
        } else if (configName === 'sell') {
            if (this.router.currentInstruction.params.childRoute?.includes('/')) {
                const sellMainCategory = dynamicPagesForNav.find(x => x.name.toLowerCase().includes(this.router.currentInstruction.params.childRoute.split('/')[0] === 'currency'
                    ? `sell ${this.router.currentInstruction.params.childRoute.split('/')[0]}`
                    : `sell ${(this.router.currentInstruction.params.childRoute.split('/')[0]).slice(0, -1)}`));

                const foundGame = sellMainCategory.gameForNav.find(x => x.slug.includes(this.router.currentInstruction.params.childRoute.split('/')[1]));
                const categorySecondRoute = this.router?.currentInstruction.fragment.split('/').slice(0, -1).join('/');

                breadCrumbSchema += `}, {
                                    "@type": "ListItem",
                                    "position": 2,
                                    "name": "${this.capitalizeTextValueConverter.toView(sellMainCategory?.name.toLowerCase() === 'sell currency' ? sellMainCategory?.name : `${sellMainCategory?.name}s`, 'sentence')}",
                                    "item": "${`${baseUrl().slice(0, -1)}${categorySecondRoute}`}"
                                }, {
                                    "@type": "ListItem",
                                    "position": 3,
                                    "name": "${this.capitalizeTextValueConverter.toView(foundGame?.name, 'sentence')}",
                                    "item": "${`${baseUrl().slice(0, -1)}${this.router?.currentInstruction.fragment}`}"
                                }]
                            }`;
            } else if (this.router.currentInstruction.params.childRoute) {
                const foundGame = dynamicPagesForNav.find(x => x.name.toLowerCase().includes(this.router.currentInstruction.params.childRoute === 'currency'
                    ? `sell ${this.router.currentInstruction.params.childRoute}`
                    : `sell ${this.router.currentInstruction.params.childRoute.slice(0, -1)}`));


                breadCrumbSchema += `}, {
                                    "@type": "ListItem",
                                    "position": 2,
                                    "name": "${this.capitalizeTextValueConverter.toView(foundGame?.name, 'sentence')}",
                                    "item": "${`${baseUrl().slice(0, -1)}${this.router?.currentInstruction.fragment}`}"
                                }]
                            }`;
            }
        } else {
            breadCrumbSchema += '}] }';
        }
        this.helper.combineApplicationLdJsonSchemasIntoOne(breadCrumbSchema, this.router);
        this.firstTimeBreadcrumbCall = false;
    }

    handleMonitoringWebsite() {
        this.healthTimeout = setInterval(async() => {
            if (!this.triggeredMonitoring) {
                try {
                    this.triggeredBanner = false;
                    this.triggeredMonitoring = true;
                    const response = await this.healthService.checkApiHealth();
                    if ((await isOnline() && !response) && !this.triggeredBanner) {
                        this.triggeredBanner = true;
                        this.eventAggregator.publish('banner-updated', { successful: 'error', text: 'Divica Sales is currently down. To check status, please', url: 'https://status.divicasales.com/' });
                        throw new Error('Divica Sales is currently down. To check status, please open https://status.divicasales.com/');
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    this.triggeredMonitoring = false;
                }
            }
        }, 60000);
    }

    getGoogleTagManagerUserIdScript(user) {
        return user
            ?
            `window.dataLayer = window.dataLayer || [];
            
            window.dataLayer.push({
                'user_id': '${user.id}',
                'email': '${user.email}'
            });

            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-P8599LD');`
            :
            `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-P8599LD');`;
    }

    async configureRouter(config, router) {
        config.options.pushState = true;
        config.title = 'DivicaSales';
        config.titleSeparator = ' - ';
        config.addAuthorizeStep(AuthorizeStep);
        config.addPreActivateStep(PreActivateStep);
        config.addPreRenderStep(MetadataStep);
        config.addPreRenderStep(SeoStep);
        config.addPostRenderStep(IntercomStep);
        config.addPostRenderStep(ScrollPageStep);
        config.map([
            {
                route: this.getRouteNameForPage(this.pages, 'Home', '') ?? '',
                name: 'home',
                moduleId: PLATFORM.moduleName('pages/home/home'),
                title: this.getTitleForPage(this.pages, 'Home'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Home'),
                }
            },
            {
                route: this.getRouteNameForPage(this.pages, 'Currency') ?? 'currency',
                name: 'currency',
                moduleId: PLATFORM.moduleName('pages/currency/currency'),
                title: this.getTitleForPage(this.pages, 'Currency'),
                hasProductSchema: true,
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Currency')
                }
            },
            {
                route: 'cart',
                name: 'cart',
                moduleId: PLATFORM.moduleName('pages/cart/cart'),
                title: 'Cart',
                settings: {
                    auth: true
                }
            },
            {
                route: this.getRouteNameForPage(this.pages, 'Terms Of Service') ?? 'terms-of-service',
                name: 'terms-of-service',
                moduleId: PLATFORM.moduleName('pages/terms-of-service/terms-of-service'),
                title: this.getTitleForPage(this.pages, 'Terms Of Service'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Terms Of Service')
                }
            },
            {
                route:
                    this.getRouteNameForPage(this.pages, 'Bug Bounty') ??
                    'bug-bounty',
                name: 'bug-bounty',
                moduleId: PLATFORM.moduleName('pages/bug-bounty/bug-bounty'),
                title: this.getTitleForPage(this.pages, 'Bug Bounty'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Bug Bounty'
                    ),
                },
            },
            {
                route: 'order-details/:id',
                name: 'order-details',
                moduleId: PLATFORM.moduleName('pages/order-details/order-details'),
                title: 'Order Details'
            },
            {
                route: this.getRouteNameForPage(this.pages, 'Copyright Policy') ?? 'copyright-policy',
                name: 'copyright-policy',
                moduleId: PLATFORM.moduleName('pages/copyright-policy/copyright-policy'),
                title: this.getTitleForPage(this.pages, 'Copyright Policy'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Copyright Policy')
                }
            },
            {
                route: this.getRouteNameForPage(this.pages, 'Privacy Policy') ?? 'privacy-policy',
                name: 'privacy-policy',
                moduleId: PLATFORM.moduleName('pages/privacy-policy/privacy-policy'),
                title: this.getTitleForPage(this.pages, 'Privacy Policy'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Privacy Policy')
                }
            },
            {
                route: 'sign-up',
                name: 'sign-up',
                moduleId: PLATFORM.moduleName('pages/auth/auth'),
                title: 'Sign Up'
            },
            {
                route: 'sign-in',
                name: 'sign-in',
                moduleId: PLATFORM.moduleName('pages/auth/auth'),
                title: 'Sign In'
            },
            {
                route: 'sign-in/reset-password',
                name: 'reset-password',
                moduleId: PLATFORM.moduleName('pages/auth/auth'),
                title: 'Reset Password'
            },
            {
                route: this.getRouteNameForPage(this.pages, 'Contact Us', 'contact') ?? 'contact',
                name: 'contact',
                moduleId: PLATFORM.moduleName('pages/contact/contact'),
                title: this.getTitleForPage(this.pages, 'Contact Us'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Contact Us')
                }
            },
            {
                route: this.getRouteNameForPage(this.pages, 'About Us') ?? 'about-us',
                name: 'about-us',
                moduleId: PLATFORM.moduleName('pages/about-us/about-us'),
                title: this.getTitleForPage(this.pages, 'About Us'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'About Us')
                }
            },
            {
                route: 'expired-link',
                name: 'expired-link',
                moduleId: PLATFORM.moduleName('pages/expired-link/expired-link'),
                title: 'Expired Link'
            },
            {
                route: this.getRouteNameForPage(this.pages, 'Customer Portal') ?? 'customer-portal',
                name: 'customer-portal',
                moduleId: PLATFORM.moduleName('pages/customer-portal/customer-portal'),
                title: this.getTitleForPage(this.pages, 'Customer Portal'),
                settings: {
                    auth: true,
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Customer Portal')
                }
            },
            {
                route: this.getRouteNameForPage(this.pages, 'Sitemap') ?? 'sitemap',
                name: 'sitemap',
                moduleId: PLATFORM.moduleName('pages/sitemap/sitemap'),
                title: this.getTitleForPage(this.pages, 'Sitemap'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Sitemap')
                }
            },
            {
                route: this.getRouteNameForPage(this.pages, 'Faq') ?? 'faq',
                name: 'faq',
                moduleId: PLATFORM.moduleName('pages/faq/faq'),
                title: this.getTitleForPage(this.pages, 'Faq'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Faq')
                }
            },
            {
                route: this.getRouteNameForPage(this.pages, '404') ?? '404',
                name: '404',
                moduleId: PLATFORM.moduleName('pages/404/404'),
                title: this.getTitleForPage(this.pages, '404'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, '404')
                }
            },
            {
                route: this.blogRoute,
                name: 'blog',
                moduleId: PLATFORM.moduleName('pages/blogs/blogs'),
                title: this.getTitleForPage(this.pages, 'Blog'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Blog')
                }
            },
            {
                route: `${this.blogRoute}/:blog`,
                name: 'blog-post',
                moduleId: PLATFORM.moduleName('pages/blogs/blogpost/blogpost'),
                title: this.getTitleForPage(this.pages, 'Blog'),
                hasBlogPostSchema: true,
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Blog')
                },
                activationStrategy: activationStrategy.replace
            },
            {
                route: `${this.blogRoute}/page/:page`,
                name: 'blog-page',
                moduleId: PLATFORM.moduleName('pages/blogs/blogs'),
                title: this.getTitleForPage(this.pages, 'Blog'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Blog')
                }
            },
            {
                route:`${this.blogRoute}/category/:category/page/:page`,
                name: 'blog-category-page',
                moduleId: PLATFORM.moduleName('pages/blogs/blogs'),
                title: this.getTitleForPage(this.pages, 'Blog'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Blog')
                }
            },
            {
                route: `${this.blogRoute}/category/:category`,
                name: 'blog-category',
                moduleId: PLATFORM.moduleName('pages/blogs/blogs'),
                title: this.getTitleForPage(this.pages, 'Blog'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Blog')
                },
            },
            {
                route: `${this.blogRoute}/author/:alias`,
                name: 'author',
                moduleId: PLATFORM.moduleName('pages/blogs/author/author'),
                title: this.getTitleForPage(this.pages, 'Blog'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Blog')
                },
                activationStrategy: activationStrategy.replace
            },
            {
                route: this.getRouteNameForPage(this.pages, 'Site Down') ?? 'site-down',
                name: 'site-down',
                moduleId: PLATFORM.moduleName('pages/site-down/site-down'),
                title: this.getTitleForPage(this.pages, 'Site Down'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Site Down')
                }
            },
            {
                route: this.getRouteNameForPage(this.pages, 'Rate Limit') ?? 'rate-limit',
                name: 'rate-limit',
                moduleId: PLATFORM.moduleName('pages/rate-limit/rate-limit'),
                title: this.getTitleForPage(this.pages, 'Rate Limit'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(
                        this.pages,
                        'Rate Limit'
                    ),
                }
            },
            {
                route: this.getRouteNameForPage(this.pages, 'Sell') ?? 'sell',
                name: 'sell',
                moduleId: PLATFORM.moduleName('pages/sell/sell'),
                title: this.getTitleForPage(this.pages, 'Sell'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Sell')
                }
            },
        ]);

        config.mapUnknownRoutes(() => {
            return {
                redirect: `${this.getRouteNameForPage(this.pages, '404') ?? '404'}`
            };
        });

        this.router = router;
    }

    getTitleForPage(pages, pageName) {
        return pages?.find(x => x.name === pageName)?.title;
    }

    getMetaDescriptionForPage(pages, pageName) {
        return pages?.find(x => x.name === pageName)?.metaDescription;
    }

    getRouteNameForPage(pages, pageName, def = null) {
        return pages?.find(x => x.name === pageName)?.routeName ?? (def !== null ? def : this.helper.toRoute(pageName));
    }

    handleReviewSchema() {
        const reviewSchema = `{
                            "@type": "Organization",
                            "@id": "${baseUrl()}#/schema/Organization/DivicaSales",
                            "url": "${baseUrl()}",
                            "logo": {
                                "@id": "${baseUrl()}#/schema/ImageObject/Logo/DivicaSales"
                            },
                            "name": "DivicaSales",
                            "telephone": "(1) 347-491-4382",
                            "email": "support@divicasales.com",
                            "address" : {
                                "@id": "${baseUrl()}#/schema/PostalAddress/DivicaSales"
                            }
                        },
                        {
                            "@type": "PostalAddress",
                            "@id": "${baseUrl()}#/schema/PostalAddress/DivicaSales",
                            "streetAddress": "1 King Street W, Suite 4800",
                            "addressLocality": "Toronto",
                            "addressRegion": "ON",
                            "postalCode": "M5H 1A1",
                            "addressCountry": "CA"
                        },
                        {
                            "@type": "ImageObject",
                            "@id": "${baseUrl()}#/schema/ImageObject/Logo/DivicaSales",
                            "url": "${baseUrl()}static/logo/ds_logo_black.svg",
                            "contentUrl": "${baseUrl()}static/logo/ds_logo_black.svg",
                            "width": {
                                "@type": "QuantitativeValue",
                                "value": 512,
                                "unitCode": "E37",
                                "unitText": "pixel"
                            },
                            "height": {
                                "@type": "QuantitativeValue",
                                "value": 512,
                                "unitCode": "E37",
                                "unitText": "pixel"
                            },
                            "caption": "DivicaSales Logo",
                            "name": "DivicaSales"
                        }`;
        this.helper.combineApplicationLdJsonSchemasIntoOne(reviewSchema);
    }

    @computedFrom('router.currentInstruction.config.route')
    get isMainPage() {
        return this.helper.excludeAll(this.router?.currentInstruction?.config?.route, ['404', 'sign-up', 'sign-in', 'sign-in/reset-password']);
    }
}
