import './manual-checkout-form.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { ImageService } from 'services/image-service';
import QRCode from 'qrcode';
import { PaymentMethodWebsite } from 'services/models/purchase-flow/paymentMethodWebsite';

@autoinject()
export class ManualCheckoutForm {
    parent;
    constructor(private sessionService: SessionService, private imageService: ImageService) {}

    bind(bindingContext) {
        this.parent = bindingContext;
        this.parent.summaryButtonState = 'disabled';
        this.parent.summaryButtonText = 'Mark as sent';
        this.parent.summaryButtonFunction = () => {
            this.parent.startOrder();
        };
    }

    @bindable currency;
    @bindable paymentMethod: PaymentMethodWebsite;
    @bindable totalPrice: number;
    @bindable qrCode;
    @bindable veriffStep;
    user;

    async attached() {
        this.user = await this.sessionService.getProfile();
        await this.updatePaymentMethodActions();
    }

    async updatePaymentMethodActions() {
        const reference = this.paymentMethod.paymentMethod.reference;

        if (reference === 'alipay') {
            await this.generateQrCode();
        } else if (reference === 'interac') {
            this.parent.summaryButtonText = 'Pay Now';
        }
    }

    async paymentMethodChanged() {
        await this.updatePaymentMethodActions();
    }

    async generateQrCode() {
        try {
            this.qrCode = await QRCode.toDataURL(this.paymentMethod.paymentMethod.qrCodeValue);
        } catch (e) {
            console.error(e);
        }
    }
}
