export interface Toast {
    title: string,
    message: string
    toastType: string
}

export enum ToastType {
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    TOOLTIP = 'text-only',
}
