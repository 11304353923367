import './ds-page-content-area.scss';
import { Router } from 'aurelia-router';
import { autoinject, bindable } from 'aurelia-framework';
import { PageContentAreaService } from 'services/page-content-area-service';
import { SessionService } from 'services/session-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { htmlTagRegex } from 'resources/constants';
import { Helper } from 'resources/extensions/helper';

@autoinject()
export class DsPageContentArea {
    @bindable key;
    @bindable alternativeKey;
    @bindable fontWeightInherit = false;
    @bindable marginBottomInherit = false;
    @bindable isCustomLegalPage = false;
    @bindable pageContent = '';
    @bindable multipleKeys = [];
    @bindable viewingAsAdmin: boolean;
    @bindable triggerBorderChange = false;
    contentElement: HTMLElement;
    adminViewSubscriber;
    htmlTagRegex = htmlTagRegex();
    legalPages = ['copyright-policy', 'privacy-policy', 'terms-of-service'];
    staticExcludeDisplayBlockTags = ['<u', '<a', '<li', '<strong'];
    retrievedContentChangedSubscriber: Subscription;
    user;

    constructor(
        private pageContentAreaService: PageContentAreaService,
        private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private router: Router,
        private helper: Helper
    ) { }

    async attached() {
        try {
            this.user = await this.sessionService.getProfile();
            this.viewingAsAdmin = await this.sessionService.getAdminView() && await this.sessionService.checkRolesForPanelAccess();
            this.setFontInheritForTitles();
            await this.setByKey();
            this.handleEventSubscriptions();
        } catch (e) {
            console.log(e);
        } finally {
            if (this.triggerBorderChange) this.setBorders('#bug-bounty');
            else if (this.legalPages.includes(this.router.currentInstruction.config.name)) this.setBorders('.legal-page');
        }
    }

    detached() {
        this.helper.disposeAllSubscribers(this);
    }

    handleEventSubscriptions() {
        this.adminViewSubscriber = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
            this.setByKey();
        });

        this.retrievedContentChangedSubscriber = this.eventAggregator.subscribe('retrieved-content-changed', () => this.setByKey());
    }

    setFontInheritForTitles() {
        this.contentElement?.querySelectorAll('h1, h2, h3, h4, h5, p, span, strong').forEach(el => {
            if (!this.isCustomLegalPage) el.classList.add('font-size-inherit');
            if (this.fontWeightInherit) el.classList.add('font-weight-inherit');
            if (this.marginBottomInherit) el.classList.add('mb-inherit');
        });
    }

    async setByKey() {
        const result = await this.pageContentAreaService.getByKey(this.key);
        if (this.viewingAsAdmin) {
            if (this.multipleKeys?.length) {
                this.pageContent = '';
                this.multipleKeys.forEach((value, index) => {
                    this.pageContent += `Key: { ${value} }${index !== this.multipleKeys.length - 1 ? '<br>' : ''}`;
                });
            } else {
                this.pageContent = `Key: { ${this.key} } ${this.alternativeKey ? `Alternative Key: { ${this.alternativeKey} }` : ''}`;
            }
            if (this.contentElement) this.contentElement.style.fontSize = '10px';
        } else {
            if (this.multipleKeys.find(x => x === this.key)) {
                if (result?.markup) {
                    const regExr = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
                    const markupResult = result.markup.replace(regExr, '');
                    this.pageContent = `<h2 style="font-size: inherit;">${markupResult}</h2>`;
                    this.setFontInheritForTitles();
                } else {
                    this.pageContent = '';
                }
            } else {
                this.pageContent = result ? result.markup : await this.getAlternativeKeyContent();
                if (!this.isCustomLegalPage) {
                    this.pageContent = this.pageContent?.replace(this.htmlTagRegex, (match) => {
                        if (!match) return;
                        if (match.includes('style="') && !match.includes('font-size: inherit;')) {
                            return match.replace(/style="([^"]*)"/, (_, existingStyles) => {
                                const updatedStyles = `${existingStyles.trim()} font-size: inherit;${this.checkForTagAndExcludeDisplayBlock(match)}`;
                                return `style="${updatedStyles}"`;
                            });
                        }
                        return match.includes('</') ? match : match.replace('>', ` style="font-size: inherit;${this.checkForTagAndExcludeDisplayBlock(match)}">`);
                    });
                }
            }

            if (this.contentElement && !this.isCustomLegalPage) this.contentElement.style.fontSize = 'inherit';
            if (this.fontWeightInherit) this.contentElement.style.fontWeight = 'inherit';
            if (this.marginBottomInherit) this.contentElement.style.marginBottom = '0';
        }
    }

    checkForTagAndExcludeDisplayBlock = (match) => this.helper.includesSome(match, this.staticExcludeDisplayBlockTags) ? '' : ' display: block;';

    async getAlternativeKeyContent() {
        if (!this.alternativeKey) return '';
        const result = await this.pageContentAreaService.getByKey(this.alternativeKey);
        return result ? result.markup : '';
    }

    async keyChanged() {
        await this.setByKey();
        if (!this.triggerBorderChange) return;
        this.setBorders('#bug-bounty');
    }

    setBorders(selector) {
        setTimeout(() => {
            const borders = Array.from(document.querySelectorAll(`${selector} p, ${selector} p span`)).filter(element => ['<hr>', '#border#', '#line#', '#horizontal-line#', '#horizontal-rule#', '&lt;hr&gt;'].some(e => e === (element as HTMLElement).innerText));
            borders.forEach(element => element.replaceWith(document.createElement('hr')));
        }, 50);
    }
}
