import './navbar.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { ProductCategoryService } from 'services/product-category-service';
import { GameService } from 'services/game-service';
import { SessionService } from 'services/session-service';
import { getAWSBucketEndpoint } from 'environment';
import { SubItem } from 'services/models/subItem';
import { AccordionCategory } from 'services/models/accordionCategory';
import { WebsiteService } from 'services/website-service';
import { IpAddressService } from 'services/ip-address-service';
import { AuthenticationExtension } from 'resources/extensions/sso_extension';
@autoinject()
export class Navbar extends AuthenticationExtension {
    @bindable user;
    @bindable buttonStyling = 'primary navbar-btn';
    @bindable currencyOptions = [];
    @bindable pages;
    productCategories = [];
    userSubscriber;
    drawer;
    togglerButton;
    currentCurrency;
    games;
    test;
    cart;
    cartSubscriber;
    formattedAccordionCategories;
    formattedCategories;
    bannerSubscriber;
    @bindable showBanner;
    preloadCategories = [{ name: 'Currency' }];
    stateBanner;
    textBanner;
    url;
    clickFunctionName;
    baseAwsEndpoint = getAWSBucketEndpoint('games');
    homePageRoute;
    sellPageRoute;

    constructor(
        private router: Router,
        sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private productCategoryService: ProductCategoryService,
        private gameService: GameService,
        private websiteService: WebsiteService,
        private ipAddressService: IpAddressService
    ) {
        super(null, sessionService);

        this.createNavigationOptions();
    }

    async bind() {
        if (!this.sessionService.geolocation) await this.ipAddressService.getIpData();
        [this.productCategories, this.currentCurrency, this.cart] = await Promise.all([
            this.productCategoryService.getAllForNav('DS'),
            this.sessionService.getCurrency(),
            this.sessionService.getCart()
        ]);
        this.formattedAccordionCategories = (await this.getFormattedCategoriesWithCurrency()).filter(x => !x.name.includes('Sell') && !x.isCurrency);
        this.formattedCategories = (await this.getFormattedCategories()).filter(x => !x.name.includes('Sell'));
    }

    async attached() {
        try {
            this.games = await this.gameService.getAll();
            this.togglerButton = document.getElementById('toggler-button');
            this.homePageRoute = this.pages.find(x => x.name === 'Home')?.routeName ?? '';
            this.sellPageRoute = this.pages.find(x => x.name === 'Sell')?.routeName ?? 'sell';
            this.handleEventSubscriptions();
        }
        catch (e) {
            console.error(e);
        }
    }

    detached() {
        this.userSubscriber?.dispose();
        this.cartSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.cartSubscriber = this.eventAggregator.subscribe('cart-updated', payload => {
            this.cart = payload.cart;
        });

        this.bannerSubscriber = this.eventAggregator.subscribe('banner-updated', payload => {
            this.showBanner = true;
            if (this.showBanner && this.router.currentInstruction.config.name === 'home') {
                const element = document.getElementById('ds-newsletter-prompt');
                if (element) {
                    element.style.display = 'none';
                }
            }
            this.stateBanner = payload.successful;
            this.textBanner = payload.text;
            this.clickFunctionName = payload.clickFunctionName;
            this.url = payload.url;
        });

        this.userSubscriber = this.eventAggregator.subscribe('user-updated', async (payload) => {
            if (payload) {
                if (payload.forDelete) {
                    this.user = undefined;
                }
                else {
                    const profile = await this.sessionService.getProfile();
                    if (payload.user.id === profile?.id) {
                        this.user = payload.user;
                    }
                }
            }
        });
    }

    redirectToSignIn(route: string = null) {
        if (route && this.sessionService.isLoggedIn()) {
            this.router.navigateToRoute(route);
            return;
        }

        this.handleAuthRedirection(route);
    }

    handleDrawerNavigationClick(something, openMenu) {
        this.drawer.open = openMenu;
    }

    handleRemoveDrawerNavigationClick() {
        const drawerContainer = document.querySelector('.drawer');
        drawerContainer.classList.remove('mdc-drawer--open');
    }

    /**
     * Function to retrieve the accordion list with currencies
     * @returns the accordion list with the currencies
     */
    async getFormattedCategoriesWithCurrency():Promise <AccordionCategory[]> {
        const accordionAuxList = await this.getFormattedCategories();
        const accordionAux = this.convertArrayToNestedCategories(accordionAuxList);
        if (accordionAux) {
            accordionAux.push(this.getCurrencyOptions());
        }
        return accordionAux;
    }

    getCurrencyOptions() {
        if (!this.currentCurrency) {
            this.sessionService.saveCurrency('USD');
            this.currentCurrency = this.currencyOptions[0]?.text;
        }

        const currencyAccordianObject = {
            name: this.currentCurrency,
            isCurrency: true,
            slug: null,
            items: []
        };

        for (const currency of this.currencyOptions) {
            if (currency.value !== this.currentCurrency) {
                currencyAccordianObject.items.push({ category: 'CurrencyOptions', name: currency.value, isCurrency: true, link: false });
            }
        }
        return currencyAccordianObject;
    }


    /**
     * Builds Navigation object for desktop
     * This same function maps the entire categories object
     */
    async getFormattedCategories() {
        const reorderCategories = ['Currency', 'Sell Currency', 'Sell Item', 'Sell Account'];
        return await Promise.all(this.productCategories?.map(async category => {
            const slug = await this.websiteService.getRouteOrDefault(category.name, null);
            const items = await Promise.all(category.gameForNav.filter(e => !e.parentGameId).map(game => this.getGame(category, game)));
            this.productCategories.sort((a, b) => reorderCategories.indexOf(a.name) - reorderCategories.indexOf(b.name)).filter(x => !x.name.includes('Sell'));
            return {
                name: category.name,
                items: items,
                slug: slug
            };
        }));
    }

    /**
     * Gets games for each category
     * If subGames exist it fills each game with those items.
     * @param {*} category
     * @param {*} game
     * @returns
     */
    getGame(category, game) {
        const dynamicRoute = this.pages.find(x => x.name.toLowerCase() === category.name.toLowerCase())?.routeName ?? category.name.toLowerCase();
        const subItemObject: SubItem = {
            category: category.name,
            name: game.name,
            shortName: game.shortName,
            slug: `/${dynamicRoute}/${game.slug[0]}`,
            link: true
        };
        const childGames = category.gameForNav.filter(g => g.parentGameId === parseInt(game.id));
        subItemObject.template = childGames.length > 0
            ? this.makeItemWithLocalImageTemplate(game.dropdownText && game.dropdownText !== '' ? game.dropdownText : game.name, game.gameNavigationIcon, true)
            : this.makeItemWithCustomImageTemplate(game.dropdownText && game.dropdownText !== '' ? game.dropdownText : game.name, game.gameNavigationIcon);
        subItemObject.items = childGames.length > 0 ?
            this.getSubGames(childGames, category)
            : null;
        return subItemObject;
    }

    getSubGames(childGames, category) {
        const dynamicRoute = this.pages.find(x => x.name.toLowerCase() === category.name.toLowerCase())?.routeName ?? category.name.toLowerCase();
        return childGames.map(childGame => {
            return {
                category: category.name,
                name: childGame.name,
                shortName: childGame.shortName,
                slug: `/${dynamicRoute}/${childGame.slug[0]}`,
                template: this.makeItemWithCustomImageTemplate(childGame.dropdownText && childGame.dropdownText !== '' ? childGame.dropdownText : childGame.name, childGame.gameNavigationIcon),
                link: true
            };
        });
    }

    makeItemWithCustomImageTemplate(text, icon) {
        const el1 = document.createElement('div');
        const innerSpan = document.createElement('span');
        innerSpan.classList.add('text-truncate');
        innerSpan.innerText = text;
        el1.prepend(innerSpan);
        el1.setAttribute('style', 'display: flex; align-items: center; text-transform: capitalize;');
        el1.classList.add('customer-dropdown-option');
        const imageContainer = document.createElement('span');
        imageContainer.setAttribute('style', icon ? 'width: 30px; min-width: 30px;' : 'width: 30px; min-width: 30px; display: flex; justify-content: center;');
        imageContainer.classList.add('me-3', 'text-center');
        if (icon) {
            const el = document.createElement('img');
            el.loading = 'lazy';
            el.alt = text;
            el.src = icon?.includes('default.svg') ? `/icons/${icon}` : `${this.baseAwsEndpoint}${icon}`;
            el.setAttribute('style', 'width: auto; max-width: 30px; max-height: 20px');
            el.innerText = icon;
            imageContainer.appendChild(el);
        } else {
            const el = document.createElement('span');
            el.classList.add('material-icons-outlined', 'text-white');
            el.innerText = 'image_not_supported';
            imageContainer.appendChild(el);
        }
        el1.prepend(imageContainer);
        return el1;
    }

    makeItemWithLocalImageTemplate(text, icon, subcategory) {
        const el1 = document.createElement('div');
        el1.innerHTML = `<span class="dropdown-nav-text ${subcategory ? 'col' : ''}">${text}</span>`;
        el1.setAttribute('style', 'display: flex; align-items: space-between; text-transform: capitalize;');
        el1.classList.add('customer-dropdown-option');
        const imageContainer = document.createElement('span');
        imageContainer.setAttribute('style', 'width: 30px; min-width: 30px;');
        imageContainer.classList.add('me-3', 'text-center');
        if (icon) {
            const el = document.createElement('img');
            el.loading = 'lazy';
            el.alt = text;
            el.src = icon?.includes('default.svg') ? `/icons/${icon}` : `${this.baseAwsEndpoint}${icon}`;
            el.setAttribute('style', 'width: auto; max-width: 30px; max-height: 20px');
            el.innerText = icon;
            imageContainer.appendChild(el);
        } else {
            const el = document.createElement('span');
            el.classList.add('material-icons-outlined', 'no-image-container', 'text-white');
            el.innerText = 'image_not_supported';
            imageContainer.appendChild(el);
        }
        el1.prepend(imageContainer);
        if (subcategory) {
            const arrowContainer = document.createElement('span');
            arrowContainer.classList.add('material-icons', 'submenu-arrow');
            arrowContainer.innerText = 'chevron_right';
            el1.append(arrowContainer);
        }
        return el1;
    }

    createNavigationOptions() {
        this.formattedCategories = [];
        for (const category of this.preloadCategories) {
            const itemObject = {
                name: category.name.toUpperCase(),
                items: []
            };
            this.formattedCategories.push(itemObject);
        }
    }

    convertArrayToNestedCategories(array) :any[] {
        const sellCategory = {
            name: 'Sell',
            items: [],
            categories: []
        };
        for (const item of array) {
            if (item.name?.startsWith('Sell')) {
                sellCategory.categories.push(item);
            }
        }
        const resultArray = [...array, sellCategory];
        return resultArray;
    }

    handleNavigationClick(item) {
        if (item) {
            this.route(item);
        }
    }

    route(route, param = undefined) {
        if (param) {
            this.router.navigate(route + '/' + param);
            return;
        }
        this.router.navigate(route);
    }

}
