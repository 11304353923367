import './ds-newsletter-prompt.scss';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-dependency-injection';
import { SessionService } from 'services/session-service';
import { CustomerService } from 'services/customer-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';

@autoinject()
export class DsNewsletterPrompt {
    constructor(private sessionService: SessionService,
        private customerService: CustomerService,
        private eventAggregator: EventAggregator,
        private router: Router) {
    }

    @bindable user;
    routerSubscription;
    @bindable showingAnotherBanner;
    newsletterPromptElement;

    async attached() {
        this.user = this.user ?? await this.sessionService.refreshProfile();
        this.handleBannerVisibility();
    }

    handleBannerVisibility() {
        if (!this.newsletterPromptElement || !this.showingAnotherBanner) return;
        this.newsletterPromptElement.style.display = 'none';
    }

    async optIn() {
        this.user.closedSubscriptionBanner = true;
        this.user.optedInForEmails = true;
        await this.customerService.updateInformation(this.user);
        this.eventAggregator.publish('banner-updated', { successful: 'success', text: 'Thank you for subscribing to our newsletter! You will now receive exclusive offers about our products. You may change your preferences at any time from your profile page', optedIn: true });
        this.hideBanner();
    }

    async dismissPrompt() {
        this.hideBanner();
        this.user.closedSubscriptionBanner = true;
        await this.customerService.updateInformation(this.user);
    }

    hideBanner() {
        if (!this.newsletterPromptElement) return;
        this.newsletterPromptElement.style.display = 'none';
    }

    showingAnotherBannerChanged() {
        this.handleBannerVisibility();
    }
}
