import './ds-phone-input.scss';
import { bindable, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { CustomerService } from 'services/customer-service';
import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { InputNumericValueChecker } from 'resources/value-converters/input-numeric-value-checker';
import { ToastService } from 'services/toast-service';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import intlTelInput from 'intl-tel-input';
import intlTelInputUtils from 'intl-tel-input/build/js/utils';
import { firebaseConfig } from 'environment';
import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithCredential, PhoneAuthProvider } from 'firebase/auth';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Helper } from 'resources/extensions/helper';

@autoinject()
export class DsPhoneInput {
    bind(bindingContext) {
        this.parent = bindingContext;
    }

    stages = {
        ENTERING_PHONE: 1,
        ENTERING_CODE: 2,
        VERIFIED: 3,
    };

    phoneElement;
    authCode;
    sentPhone;
    countryCodes;
    verificationStage = this.stages.ENTERING_PHONE;
    @bindable requireSms;
    @bindable verifiedFunction;
    @bindable user;
    @bindable onCart;
    @bindable phoneInput: intlTelInput.Plugin;
    @bindable inputStyle;
    @bindable enteringToken;
    @bindable loading;
    @bindable labelStyle;
    @bindable labelText;
    @bindable containLabel;
    @bindable flowPage;

    showMessage;
    phoneInputState;
    authCodeState;
    justReset = true;
    errorTimeout;
    profileErrorTimeout;
    showCallMeInstead;
    firedFunction;
    showMiniSpinnerPhone;
    miniSpinnerPhoneStopWatch;
    showMiniSpinnerAuthCode;
    miniSpinnerAuthCodeStopWatch;
    whiteXMark;
    whiteAuthCodeXMark;
    toastPhoneInputSent;
    toastAuthCodeSent;
    phoneInputFocusInStopWatch;
    authCodeFocusInStopWatch;
    phoneInputFocused;
    profileSubmitTimeout;
    submitCodeTimeout;
    timeouts;

    lastEventTrigered;
    arrowRotated = false;
    arrowTrigered;

    countryToUse;
    parent;
    countryCodeSelector;
    phoneNumber;
    phoneSubscriber;
    inputContainer;
    firebaseUserCredential;
    recaptchaVerifier;
    verificationId;
    recaptchaWidgetId;
    firebaseProvider;
    submitPhoneTimeout;
    usingTwilio;

    constructor(
        private router: Router,
        private customerService: CustomerService,
        private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private inputNumericValueChecker: InputNumericValueChecker,
        private toastService: ToastService,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private helper: Helper
    ) {
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    async attached() {
        await this.sessionService.getGeolocation();
        this.loading = true;

        initializeApp(firebaseConfig());

        if (!this.phoneElement) return;

        this.phoneInput = intlTelInput(this.phoneElement, {
            separateDialCode: true,
            utilsScript: intlTelInputUtils,
            preferredCountries: ['us', 'ca', 'gb'],
            dropdownContainer: this.inputContainer
        });

        this.user = await this.sessionService.refreshProfile();
        this.countryToUse = this.user?.phoneCountryFlag
            ? this.user.phoneCountryFlag
            : this.user?.country
                ? this.user.country.toLowerCase()
                : await this.sessionService.getCountry();
        if (this.countryToUse && this.countryToUse !== 'N/A') {
            this.phoneInput.setCountry(this.countryToUse.toLowerCase());
        }
        if (this.user?.phoneNumber) {
            this.phoneNumber = this.user.phoneNumber;
            this.phoneInput.setNumber(this.user.phoneNumber);
        }
        if (this.user?.phoneNumberConfirmed && this.user?.phoneNumber) {
            if (this.phoneInput.isValidNumber()) {
                this.phoneInputState = 'success';
            } else {
                this.phoneInputState = 'error';
            }
        }

        //Problem with the image path
        // this.overrideITI();

        this.handleEventSubscriptions();
        this.loading = false;
    }

    async detached() {
        this.phoneInput?.destroy();
        this.phoneSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.phoneSubscriber = this.eventAggregator.subscribe('phone-updated', (payload) => {
            if (payload.successful) {
                this.user.phoneNumberConfirmed = true;
                this.user.phoneNumberInReview = false;
                if (!this.onCart) {
                    this.resetPhoneInput();
                }
            }
        });
    }

    overrideITI() {
        const selectOuterContainer = this.countryCodeSelector.querySelector(
            '.iti__flag-container'
        );
        const selectContainer =
            this.countryCodeSelector.querySelector('.iti__arrow');
        selectContainer.innerHTML +=
            '<div class="override-arrow-container"></div>';
        const selectContainerForArrow = this.countryCodeSelector.querySelector(
            '.override-arrow-container'
        );
        const newArrowIcon =
            '<img class="faq-arrow-icon" src="/static/icons/arrow-white.svg" alt="arrow icon" loading="lazy">';
        selectContainerForArrow.innerHTML = newArrowIcon;
        this.arrowTrigered = selectContainer.querySelector('.faq-arrow-icon');
        const handleClick = () => {
            if (this.arrowRotated) {
                this.arrowTrigered.style.transform = 'rotate(0deg)';
                this.arrowRotated = false;
            } else {
                if (this.lastEventTrigered !== 'focusOut') {
                    this.arrowTrigered.style.transform = 'rotate(180deg)';
                    this.arrowRotated = true;
                }
            }
        };
        const handleFocusOut = () => {
            if (this.arrowTrigered) {
                this.arrowTrigered.style.transform = 'rotate(0deg)';
                this.arrowRotated = false;
                this.lastEventTrigered = 'focusOut';
                setTimeout(() => {
                    this.lastEventTrigered = '';
                }, 200);
            }
        };
        selectOuterContainer.addEventListener('click', handleClick);
        selectOuterContainer.addEventListener('focusout', handleFocusOut);
    }

    async submitPhone() {
        let result = false;
        if (this.phoneInput.isValidNumber()) {
            this.usingTwilio = false;
            const cc = this.phoneInput.getSelectedCountryData().dialCode;
            const num = this.phoneInput.getNumber().slice(1 + cc.length);
            const cf = this.phoneInput.getSelectedCountryData().iso2;

            if (!cc || !num) {
                await this.toastService.showToast('Error', 'Please enter both your country code and phone number.', 'error');
                return result;
            }
            try {
                this.submitPhoneTimeout = setTimeout(async () => {
                    await this.registerPhone(cc, num, cf);
                }, 2000);
                result = true;
            } catch (e) {
                console.log(e);
            }
        } else {
            await this.toastService.showToast('Error', 'Please enter a valid phone number to proceed with your phone verification.', 'error');
        }
        return result;
    }

    async codeNotSent() {
        if (this.requireSms === 'true') {
            this.showCallMeInstead = true;
            await this.submitPhone();
        }
    }

    async setPhoneNumberNotInReview() {
        this.verificationStage = this.stages.ENTERING_PHONE;
        this.enteringToken = false;
        this.clearAuthCode();
        this.authCodeState = '';
        this.parent.shouldShowPhoneNumber = true;
        this.user.phoneNumberInReview = false;
        this.usingTwilio = false;
        this.eventAggregator.publish('user-updated', {
            user: await this.sessionService.getProfile(),
        });
    }

    async callInstead() {
        if (this.phoneInput.isValidNumber()) {
            const cc = this.phoneInput.getSelectedCountryData().dialCode;
            const num = this.phoneInput.getNumber().slice(1 + cc.length);
            if (!cc || !num) {
                await this.toastService.showToast('Account Error', 'Please enter both your country code and phone number.', 'error');
                return;
            }
            try {
                const result = await this.customerService.requestCall();
                if (result === true) {
                    await this.toastService.showToast('You will receive a call with a code.', 'Please enter the code provided to confirm the phone number.', 'Info');
                    this.phoneInputState = null;
                    this.verificationStage = this.stages.ENTERING_CODE;
                    this.enteringToken = true;
                    this.parent.shouldShowPhoneNumber = false;
                    this.user.phoneNumberInReview = true;
                    this.usingTwilio = false;
                    this.eventAggregator.publish('user-updated', {
                        user: await this.sessionService.refreshProfile(),
                    });
                } else {
                    this.firedFunction = false;
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            await this.toastService.showToast('Account Error', 'Please enter a valid phone number to proceed with your phone verification.', 'error');
        }
    }

    getElementStateClass(state, isInputPhone) {
        if (state === 'success') {
            return isInputPhone ? 'ds-input--success' : 'ds-form-control--success';
        } else if (state === 'error' || state === 'inactive') {
            return isInputPhone ? 'ds-input--error' : 'ds-form-control--error';
        }
    }

    async checkIfPhoneValid() {
        this.timeouts = [this.profileErrorTimeout, this.miniSpinnerPhoneStopWatch, this.phoneInputFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.whiteXMark = false;
        const result = this.phoneInput?.isValidNumber();
        if (this.phoneNumber) {
            if (result && this.phoneInputState !== 'error' && !this.firedFunction) {
                if (this.requireSms === 'true') {
                    const response = await this.submitPhone();
                    this.showMiniSpinnerPhone = false;
                    if (response) {
                        this.phoneInputState = 'success';
                    } else {
                        this.phoneInputState = 'error';
                    }
                }
                else {
                    this.showMiniSpinnerPhone = false;
                    this.phoneInputState = 'success';
                }
            } else if (!this.firedFunction) {
                this.phoneInputState = 'error';
                if (!this.toastPhoneInputSent) {
                    await this.toastService.showToast('Error', 'Please enter a valid phone number to proceed with your phone verification.', 'error');
                }
            }
        }
        this.phoneInputFocused = false;
        return result;
    }

    phoneInputFocusIn() {
        this.firedFunction = false;
        this.showMiniSpinnerPhone = false;
        this.toastPhoneInputSent = false;
        this.phoneInputState = 'typing';
        this.whiteXMark = true;

        this.phoneInputFocusInStopWatch = setTimeout(() => {
            if (this.phoneNumber !== undefined) {
                this.isPhoneValid();
            }
        });
    }

    validateInput(event: KeyboardEvent) {
        if (!this.inputNumericValueChecker.toView(event)) return false;
        const charCode = (event.which) ? event.which : event.keyCode;
        const ctrlPlusKey = event.ctrlKey && [65, 67, 86].includes(charCode);
        if ([37, 38, 39, 40, 8, 46].includes(charCode) || ctrlPlusKey) return true;
        const { iso2, dialCode } = this.phoneInput.getSelectedCountryData();
        const num = this.phoneInput.getNumber().slice(1 + dialCode.length) + event.key;
        const validationError = this.getValidationError(num, iso2);
        return !(validationError === PhoneNumberUtil.ValidationResult.TOO_LONG || (this.phoneInput.getNumber().slice(1) + event.key).length > 15);
    }

    getValidationError(number, countryCode) {
        try {
            const phoneUtil = PhoneNumberUtil.getInstance();
            const numberObj = phoneUtil.parseAndKeepRawInput(number, countryCode);
            return phoneUtil.isPossibleNumberWithReason(numberObj);
        } catch (e) {
            if (e.message === 'Invalid country calling code') {
                return PhoneNumberUtil.ValidationResult.INVALID_COUNTRY_CODE;
            }
            if (e.message === 'Phone number too short after IDD' || e.message === 'The string supplied is too short to be a phone number') {
                return PhoneNumberUtil.ValidationResult.TOO_SHORT;
            }
            if (e.message === 'The string supplied is too long to be a phone number') {
                return PhoneNumberUtil.ValidationResult.TOO_LONG;
            }
            return -99;
        }
    }

    async isPhoneValid(ev = { key: '' }) {
        const { dialCode } = this.phoneInput.getSelectedCountryData();
        const num = this.phoneInput.getNumber().slice(1 + dialCode.length);
        if (num === dialCode) return this.clearPhoneInput();
        if (ev.key === 'Enter') {
            await this.checkIfPhoneValid();
        } else {
            this.justReset = false;
            this.phoneInputState = 'typing';
            this.showMiniSpinnerPhone = false;
            this.whiteXMark = true;
            this.timeouts = [this.profileErrorTimeout, this.miniSpinnerPhoneStopWatch, this.phoneInputFocusInStopWatch, this.submitPhoneTimeout, this.profileSubmitTimeout];
            this.clearationTimeoutValueConverter.toView(this.timeouts);
            const result = this.phoneInput?.isValidNumber();
            if (this.phoneNumber) {
                this.miniSpinnerPhoneStopWatch = setTimeout(() => {
                    this.whiteXMark = false;
                    this.showMiniSpinnerPhone = true;
                }, 1000);
                this.profileSubmitTimeout = setTimeout(async () => {
                    if (result && this.requireSms === 'true') {
                        this.firedFunction = true;
                        const response = await this.submitPhone();
                        this.showMiniSpinnerPhone = false;
                        if (response) {
                            this.phoneInputState = 'success';
                        } else {
                            this.phoneInputState = 'error';
                        }
                    } else {
                        this.showMiniSpinnerPhone = false;
                        this.phoneInputState = 'success';
                        this.profileErrorTimeout = setTimeout(async () => {
                            if (!result) {
                                this.showMiniSpinnerPhone = false;
                                this.phoneInputState = 'error';
                                this.toastPhoneInputSent = true;
                                await this.toastService.showToast('Error', 'Please enter a valid phone number to proceed with your phone verification.', 'error');
                            } else {
                                this.showMiniSpinnerPhone = false;
                                this.phoneInputState = 'success';
                                this.toastPhoneInputSent = false;
                            }
                        }, 2000);
                    }
                }, 2000);
            }
            return result;
        }
    }

    clearPhoneInput() {
        this.timeouts = [this.profileErrorTimeout, this.miniSpinnerPhoneStopWatch, this.phoneInputFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.phoneInput.setNumber('');
        this.phoneNumber = null;
        this.justReset = true;
        this.phoneInputState = null;
        this.usingTwilio = false;
    }

    clearAuthCode() {
        this.timeouts = [this.errorTimeout, this.miniSpinnerAuthCodeStopWatch, this.authCodeFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.authCode = null;
    }

    async authCodeFocusOut() {
        this.whiteAuthCodeXMark = false;
        this.timeouts = [this.errorTimeout, this.miniSpinnerAuthCodeStopWatch, this.authCodeFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.authCode) {
            if (
                this.authCode?.length === (this.usingTwilio ? 7 : 6) &&
                    this.authCodeState !== 'error' &&
                    !this.firedFunction
            ) {
                const response = await this.submitCode();
                this.showMiniSpinnerAuthCode = false;
                if (response) {
                    this.authCodeState = 'success';
                } else {
                    this.authCodeState = 'error';
                }
            } else if (!this.firedFunction) {
                this.authCodeState = 'error';
                this.showCallMeInstead = true;
                if (!this.toastAuthCodeSent) {
                    await this.toastService.showToast('Error', 'Please enter a valid verification code.', 'error');
                }
            }
        }
    }

    authCodeFocusIn() {
        this.firedFunction = false;
        this.showMiniSpinnerAuthCode = false;
        this.toastAuthCodeSent = false;
        this.authCodeState = 'typing';
        this.whiteAuthCodeXMark = true;

        this.authCodeFocusInStopWatch = setTimeout(() => {
            if (this.authCode !== undefined) {
                this.authCodeUpdatedOnKeyPress();
            }
        });
    }

    async authCodeUpdatedOnKeyPress(ev = { key: '' }) {
        if (ev.key === 'Enter') {
            await this.authCodeFocusOut();
        } else {
            this.authCodeState = 'typing';
            this.showMiniSpinnerAuthCode = false;
            this.whiteAuthCodeXMark = true;
            this.timeouts = [this.errorTimeout, this.miniSpinnerAuthCodeStopWatch, this.authCodeFocusInStopWatch, this.submitCodeTimeout];
            this.clearationTimeoutValueConverter.toView(this.timeouts);
            if (this.authCode) {
                this.miniSpinnerAuthCodeStopWatch = setTimeout(() => {
                    this.whiteAuthCodeXMark = false;
                    this.showMiniSpinnerAuthCode = true;
                }, 1000);
                this.submitCodeTimeout = setTimeout(async () => {
                    if (this.authCode?.length === this.usingTwilio ? 7 : 6) {
                        this.firedFunction = true;
                        const response = await this.submitCode();
                        this.showMiniSpinnerAuthCode = false;
                        if (response) {
                            this.authCodeState = 'success';
                        } else {
                            this.authCodeState = 'error';
                        }
                    } else {
                        this.errorTimeout = setTimeout(async () => {
                            if (this.authCode?.length !== this.usingTwilio ? 7 : 6) {
                                this.showMiniSpinnerAuthCode = false;
                                this.authCodeState = 'inactive';
                                this.showCallMeInstead = true;
                                this.toastAuthCodeSent = true;
                                await this.toastService.showToast('Error', 'Please enter a valid verification code.', 'error');
                            }
                        }, 2000);
                    }
                }, 2000);
            }
        }
    }

    async resetPhoneInput() {
        this.user = await this.sessionService.refreshProfile();
        this.countryToUse = this.user.phoneCountryFlag
            ? this.user.phoneCountryFlag
            : await this.sessionService.getCountry();
        this.phoneInput.setCountry(this.countryToUse?.toLowerCase());
        this.phoneInput.setNumber(this.user.phoneNumber);
        this.phoneNumber = this.user.phoneNumber;
    }

    async submitCode(): Promise<boolean> {
        let result = false;
        if (this.authCode?.length === this.usingTwilio ? 7 : 6) {
            try {
                let codeSubmitted = false;
                const codeIsValid = this.usingTwilio ? true : await this.isCodeValid(this.verificationId, this.authCode);
                if (codeIsValid) {
                    const updateUser = await this.customerService.verifyPhone(this.authCode, this.usingTwilio, this.verificationId, await this.firebaseUserCredential?.user?.getIdToken());
                    if (updateUser) {
                        codeSubmitted = true;
                        await this.toastService.showToast('Success!', 'Phone number has been validated succesfully', 'success');
                        this.showMiniSpinnerAuthCode = false;
                        this.authCodeState = 'success';
                        this.user.phoneNumberConfirmed = true;
                        this.user.phoneNumberInReview = false;
                        result = true;
                        if (this.verifiedFunction) {
                            this.verifiedFunction(result);
                        }
                        this.submitCodeTimeout = setTimeout(async () => {
                            this.verificationStage = this.stages.VERIFIED;
                            this.eventAggregator.publish('phone-updated', {
                                successful: true,
                            });
                            this.eventAggregator.publish('user-updated', {
                                user: await this.sessionService.refreshProfile(),
                            });
                        }, 2000);
                    }
                }
                if (!codeSubmitted) {
                    this.showMiniSpinnerAuthCode = false;
                    this.firedFunction = false;
                    this.authCodeState = 'error';
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            await this.toastService.showToast('Error', `Please enter a valid ${this.usingTwilio ? 7 : 6} digit verification code to proceed with your phone verification.`, 'error');
        }
        return result;
    }

    enteringTokenChanged() {
        if (!this.enteringToken) {
            this.verificationStage = this.stages.ENTERING_PHONE;
            this.phoneInputState = 'success';
            this.firedFunction = false;
            this.showCallMeInstead = false;
            this.clearAuthCode();
            this.authCodeState = '';
        }
    }

    displayMessage() {
        this.showMessage = true;
    }

    async registerPhone(cc, num, cf) {
        const registerPhone = await this.customerService.registerPhone(cc, num, cf);

        if (registerPhone) {
            const auth = getAuth();
            if (!this.recaptchaVerifier) {
                this.recaptchaVerifier = new RecaptchaVerifier(auth, 'firebase-button', {
                    'size': 'invisible',
                    'callback': () => {
                        return true;
                    }
                });
                this.recaptchaWidgetId = await this.recaptchaVerifier.render();
            }
            this.firebaseProvider = new PhoneAuthProvider(auth);

            try {
                const verificationId = await this.firebaseProvider.verifyPhoneNumber(`+${cc + num}`, this.recaptchaVerifier);
                await this.toastService.showToast('Info', 'A code has been sent to your mobile device.', 'info');
                this.phoneInputState = null;
                this.verificationStage = this.stages.ENTERING_CODE;
                this.parent.shouldShowPhoneNumber = false;
                this.user.phoneNumberInReview = true;
                this.eventAggregator.publish('user-updated', { user: await this.sessionService.refreshProfile() });
                this.verificationId = verificationId;
            } catch (error) {
                this.recaptchaVerifier.recaptcha.reset(this.recaptchaWidgetId);
                if (this.helper.includesSome(error?.message, ['too-many-requests', 'error-code:-39'])) {
                    this.toastService.showToast('Error', 'You have requested phone verification too many times recently. Ref: FB', 'error');
                } else {
                    this.toastService.showToast('Error', 'Please enter a valid phone number to proceed with your phone verification.', 'error');
                }
                this.phoneInputState = 'error';
                this.firedFunction = false;
            }
        }
    }

    checkPasteText() {
        navigator.clipboard.readText().then(text => {
            const numStr = /^\d+$/;
            if (numStr.test(text)) {
                this.phoneNumber += text;
            }
        });
    }

    async isCodeValid(verificationId, authCode) {
        let result = false;
        const auth = getAuth();
        const credential = PhoneAuthProvider.credential(verificationId, authCode);
        try {
            this.firebaseUserCredential = await signInWithCredential(auth, credential);
            if (this.firebaseUserCredential) result = true;
        } catch (error) {
            if (error.message?.includes('invalid-verification-code')) {
                this.toastService.showToast('Error', 'Please enter a valid 6 digit verification code to proceed with your phone verification.', 'error');
            } else {
                this.toastService.showToast('Error', 'Please enter a valid verification code to proceed with your phone verification.', 'error');
            }
        }
        return result;
    }
}
